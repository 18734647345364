import { createElement, useDebugValue, useEffect, useState } from "react"
import { getMedicRequest } from "../../services/MedicService"
import { createTimerRequest, createTimerTimeUnitRequest, updateTimerRequest } from "../../services/TimerService"
import moment from "moment-timezone"
import { TimeUnit, Timer, TimerStatus } from "./Timer-types"
import { useAppDispatch, useAppSelector } from "../../store/hooks/redux"
import { setIsActive } from "../../slices/CommonInfoSlice"
import { useLocation, useNavigate } from "react-router-dom"
import { useIdleTimer } from "react-idle-timer"



export interface TimerModelProps {
    
}

export const useTimerModel = (/*props: TimerModelProps*/) => {

    const dispatch = useAppDispatch();
    const { medic, isActive } = useAppSelector(state => state.mainReducer)
    const { isDocumentsValid, isECPFlashLoaded } = useAppSelector(state => state.appReducer)

    const { card, identCard } = useAppSelector(state => state.examReducer)


    // TIMER // 

    const [clockFace,  setClockFace] = useState <{h: string, m: string, s: string}> ({h: '00', m: '00', s: '00'})
    const [pauseClockFace,  setPauseClockFace] = useState <{h: string, m: string, s: string}> ({h: '00', m: '00', s: '00'})
    const [status, setStatus]        = useState <TimerStatus>                       (TimerStatus.FINISHED)
    const [time,   setTime]          = useState <number>                            (0)
    const [pauseTime, setPauseTime]  = useState <number>                            (0)

    const [medicId,    setMedicId]    = useState <number> (0)
    const [numberOfCreatedCards, setNumberOfCreatedCards] = useState<number>(0)
    const [timerId,    setTimerId]    = useState <number> (0)
    const [lastUnit, setLastUnit] = useState <TimeUnit> ()

    const [listenActivity, setListenActivity] = useState<boolean>(false)

    useEffect(() => {
        if (status === TimerStatus.RUNNING) { 
            dispatch(setIsActive(true))
            setListenActivity(true)
        } else {
            dispatch(setIsActive(false))
            setListenActivity(false)
        }
    }, [status])

    useEffect(() => {
        if (listenActivity && !isActive) {
            handlePauseTimer()
        }
    }, [isActive])

    useEffect(()=>{
        const setOfflineStatusEvent = () => { setStatus(TimerStatus.OFFLINE) }
        const getServerTimerEvent = async () => { await getServerTimer() }
        const getPauseTimerEvent = async () => { await getServerTimer(); getPauseTimer(); }
        (async () => {
            await getServerTimer(); getPauseTimer()
            window.addEventListener('offline', setOfflineStatusEvent)
            window.addEventListener('online', getServerTimerEvent)
            window.addEventListener('visibilitychange', getPauseTimerEvent)
        })()
        return () => {
            window.removeEventListener('offline', setOfflineStatusEvent)
            window.removeEventListener('online', getServerTimerEvent)
            window.removeEventListener('visibilitychange', getPauseTimerEvent)
        }
    },[])

    useEffect(()=>{if(!isDocumentsValid && medicId){removeTimer()}},[isDocumentsValid, medicId])
    useEffect(()=>{if(!isDocumentsValid && !isECPFlashLoaded){removeTimer()}},[isDocumentsValid, isECPFlashLoaded])

    useEffect(() => {
        updateClockFace()
        const interval = setInterval(() => {
            if (status === TimerStatus.RUNNING) {
                setTime(prev => prev + 1)
            } else {
                clearInterval(interval)
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [time, status])

    useEffect(() => {
        updatePauseClockFace()
        const interval = setInterval(() => {
            if (status === TimerStatus.PAUSED) {
                setPauseTime(prev => prev + 1)
            } else {
                clearInterval(interval)
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [pauseTime, status])
    
    useEffect(()=>{
        getPauseTimer()
    }, [status, time, lastUnit])

    const getPauseTimer = () => {
        if (status === TimerStatus.FINISHED) {
            setPauseTime(time)
            updatePauseClockFace()
        }
        if (status === TimerStatus.PAUSED && (lastUnit?.finish && lastUnit?.finish !== null) ) {
            const curTime = moment.tz('Europe/Moscow')
            const pauseTime = moment.tz(lastUnit.created_at!.substring(0, 11) + lastUnit.finish, 'Europe/Moscow')
            setPauseTime( Math.trunc(moment.duration(curTime.diff(pauseTime)).asSeconds()) )
        }
    }


    // TODO: replace with getTimer(medicId)
    const getMedic = async () => {
        try {
            const responce = await getMedicRequest() 
            console.log(responce.data.data)
            return responce.status === 200 ? responce.data : false
        } catch (e) {
            console.log(e)
            return false
        }
    }

    //TODO: get server timer теперь будет запрашивать медика и брать его таймер
    const setTimer = (timer: Timer) => {
        // medic id брать из slice

        //setMedicId(medic?.id ? medic?.id : 0) // попробуй medic?.id ?? 0  //выдавал баг из-за которого залипал таймер (потеря id медика)
        
        // number_of_created_cards брать из slice, не запрашивать нужно всё равно

        setStatus(timer.status!) 
        setTimerId(timer.id!)
        const lastUnit = timer.units![timer.units!.length-1]
        setLastUnit(lastUnit)
        let duration = timer.sum_of_units!
        if (lastUnit.finish === null) {
            const curTime = moment.tz('Europe/Moscow')
            const startTime = moment.tz(lastUnit.created_at!.substring(0, 11) + lastUnit.start, 'Europe/Moscow')
            duration += Math.trunc(moment.duration(curTime.diff(startTime)).asSeconds())
        }
        setTime(duration)
    }

    const getServerTimer = async () => {
        const medic = await getMedic()
        if (medic) {
            setMedicId(medic.id)
            setNumberOfCreatedCards(medic.number_of_created_cards)
            if (medic.current_timer !== false) {
                setStatus(medic.current_timer.status) 
                //if (medic.current_timer.status !== TimerStatus.FINISHED) {
                    setTimerId(medic.current_timer.id)
                    const lastUnit = medic.current_timer.units[medic.current_timer.units.length-1]
                    setLastUnit(lastUnit)
                    let duration = medic.current_timer.sum_of_units
                    if (lastUnit.finish === null) {
                        const curTime = moment.tz('Europe/Moscow')
                        const startTime = moment.tz(lastUnit.created_at.substring(0, 11) + lastUnit.start, 'Europe/Moscow')
                        duration += Math.trunc(moment.duration(curTime.diff(startTime)).asSeconds())
                    }
                    setTime(duration)
                //}
            }
        }
    }
        
    const updateClockFace = () => {
        const t = {
            //d: Math.floor(time/(60*60*24)),
            h: Math.floor((time/(60*60)) % 24),
            m: Math.floor((time/60) % 60),
            s: Math.floor(time % 60), 
        }
        setClockFace({
            //d: d.toString(),
            h: (t.h.toString().length > 1 ? t.h.toString() : '0' + t.h),
            m: (t.m.toString().length > 1 ? t.m.toString() : '0' + t.m),
            s: (t.s.toString().length > 1 ? t.s.toString() : '0' + t.s),
        })
    }

    const updatePauseClockFace = () => {
        const t = {
            //d: Math.floor(time/(60*60*24)),
            h: Math.floor((pauseTime/(60*60)) % 24),
            m: Math.floor((pauseTime/60) % 60),
            s: Math.floor(pauseTime % 60), 
        }
        setPauseClockFace({
            //d: d.toString(),
            h: (t.h.toString().length > 1 ? t.h.toString() : '0' + t.h),
            m: (t.m.toString().length > 1 ? t.m.toString() : '0' + t.m),
            s: (t.s.toString().length > 1 ? t.s.toString() : '0' + t.s),
        })
    }

    const startTimer = async () => {
        const timer = await createTimerRequest({
            status: TimerStatus.RUNNING,
            units: [{start: moment.tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss') + 'Z'}],
            employeeId: medicId
        })
        console.log('TIMER')
        console.log(timer)
        setTimer(timer.data)
        //await getServerTimer()
    }

    const pauseTimer = async () => {
        const timer = await updateTimerRequest({
            id: timerId,
            status: TimerStatus.PAUSED,
            units: [{
                id: lastUnit?.id,
                finish: moment.tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss') + 'Z'
            }]
        })
        console.log('TIMER')
        console.log(timer)
        setTimer(timer.data)
        //await getServerTimer()
    }

    const resumeTimer = async () => {
        await createTimerTimeUnitRequest(timerId, {
            start: moment.tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss') + 'Z'
        })
        const timer = await updateTimerRequest({id: timerId, status: TimerStatus.RUNNING})
        console.log('TIMER')
        console.log(timer)
        setTimer(timer.data)
        //await getServerTimer()
    }

    const removeTimer = async () => {
        let timer: any
        if (status === TimerStatus.PAUSED) {
            timer = await updateTimerRequest({id: timerId, status: TimerStatus.FINISHED})
        } else {
            timer = await updateTimerRequest({
                id: timerId,
                status: TimerStatus.FINISHED,
                units: [{
                    id: lastUnit?.id,
                    finish: moment.tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                }]
            })
        }
        console.log('TIMER')
        console.log(timer)
        setTimer(timer.data)
        //await getServerTimer()
    }

    // const setTimer = async () => {
    //     if (status === TimerStatus.FINISHED) {
    //         await startTimer()
    //     } 
    //     else if (status === TimerStatus.PAUSED) {
    //         await resumeTimer()
    //     }
    //     else if (status === TimerStatus.RUNNING) {
    //         await pauseTimer()
    //     }
    // }

    const [openModalPauseTimer, setOpenModalPauseTimer] = useState<boolean>(false)
    const [openModalFinishTimer, setOpenModalFinishTimer] = useState<boolean>(false)
    const [counter, setCounter] = useState<number>(10)

    useEffect(() => {
        const interval = setInterval(() => {
            if ((openModalPauseTimer || openModalFinishTimer) && counter > 0) {
                setCounter(counter - 1)
                console.log(counter)
            } else {
                clearInterval(interval)
                if (openModalPauseTimer) {
                    handlePauseTimer()
                }
                if (openModalFinishTimer) {
                    handleRemoveTimer()
                }
                setCounter(10)
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [openModalPauseTimer, openModalFinishTimer, counter])

    const handlePauseTimer = async () => {
        setOpenModalPauseTimer(false)
        await pauseTimer()
    }

    const handleRemoveTimer = async () => {
        setOpenModalFinishTimer(false)
        await removeTimer()
    }

    const handleSetTimer = async () => {
        if (status === TimerStatus.FINISHED) {
            await startTimer()
        } 
        else if (status === TimerStatus.PAUSED) {
            await resumeTimer()
        }
        else if (status === TimerStatus.RUNNING) {
            const medic = await getMedic()
            setNumberOfCreatedCards(medic.number_of_created_cards + (card !== null || identCard !== null ? 1 : 0))

            reset(); pause()
            setOpenModalPauseTimer(true)
            setListenActivity(false)
        }
    }

    const handleFinishTimer = async () => {
        const medic = await getMedic()
        setNumberOfCreatedCards(medic.number_of_created_cards + (card !== null || identCard !== null ? 1 : 0))

        reset(); pause()
        setOpenModalFinishTimer(true)
        setListenActivity(false)
    }

    const handleContinue = () => {
        setOpenModalFinishTimer(false)
        setOpenModalPauseTimer(false)
        setCounter(10)
        start()
        setListenActivity(true)
    }



    // ACTIVITY // 

    const timeout = 25_000
    const promptBeforeIdle = 10_000
    const [openModalConfirmActive, setModalConfirmActive] = useState<boolean>(false)
    const [remaining, setRemaining] = useState<number>(timeout)
    const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)

    const { getRemainingTime, activate, start, pause, reset, resume } = useIdleTimer({
        onIdle: () => { handleOnPause() },
        onActive: () => { setModalConfirmActive(false) },
        onPrompt: () => { setModalConfirmActive(true) },
        timeout,
        promptBeforeIdle,
        throttle: 500,
        startManually: true,
    })

    const handleOnContinue = () => { 
        activate()
    }

    const handleOnPause = () => {
        dispatch(setIsActive(false))
        setModalConfirmActive(false)
        reset(); pause()
    }

    // useEffect(()=>{
    //     console.log(timeTillPrompt)
    // },[timeTillPrompt])

    // useEffect(() => {
    //     console.log('isActive = ' + isActive)
    // }, [isActive])

    useEffect(() => {
        if (isActive === true) { start() } else { handleOnPause() }

        const interval = setInterval(() => {
            if (isActive) {
                //resume()
                setRemaining(Math.ceil(getRemainingTime() / 1000))
            } else {
                clearInterval(interval)
            }
        }, 500)

        return () => {
            clearInterval(interval)
        }
    }, [isActive])


    // LOCATION //

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(()=>{
        getPauseTimer(); 
    },[location])

    useEffect(() => {
        if (location.pathname !== '/meds' && status === TimerStatus.RUNNING) { 
            // Показывать модалку "поставить таймер на паузу"
            pauseTimer() 
        }
    }, [location])

    useEffect(() => {
        if ((isDocumentsValid && isECPFlashLoaded) && status === TimerStatus.RUNNING && location.pathname !== '/meds') { 
            navigate('/meds') 
        }
    }, [status])


    return {
        clockFace,
        status,

        openModalConfirmActive,
        remaining,
        handleOnPause,
        handleOnContinue,

        numberOfCreatedCards,
        openModalPauseTimer,
        setOpenModalPauseTimer,
        openModalFinishTimer,
        setOpenModalFinishTimer,

        handlePauseTimer,
        handleRemoveTimer,
        handleFinishTimer,
        handleSetTimer,
        counter,
        handleContinue,

        pauseClockFace,
        location,
        isActive,
        medicStatus: medic ? medic.documents_general_status : undefined , 
        isDocumentsValid: isDocumentsValid ,
        isECPFlashLoaded: isECPFlashLoaded,
    }
}